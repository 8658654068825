<script>
  import {
    required,
    email,
    helpers
  } from "@vuelidate/validators";
  import useVuelidate from "@vuelidate/core";
  import axios from 'axios';
  import appConfig from "../../../app.config";
  import serverConfig from '@/helpers/config';
  import Swal from "sweetalert2";

  export default {
    setup() {
      const backgroundImg = `url('${serverConfig.EP}public/assets/img/background-logo.png')`

      return {
        v$: useVuelidate(),
        backgroundImg
      };
    },
    page: {
      title: "Register",
      meta: [{
        name: "description",
        content: appConfig.description,
      }, ],
    },
    components: {},
    data() {
      return {
        UrlServer: serverConfig.EP,
        config:serverConfig,
        user: {
          username: "",
          email: "",
          firstName:"",
          lastName:"",
          phoneNumber:"",
          password: "",
          confirm_password: "",
        },
        submitted: false,
        regError: null,
        tryingToRegister: false,
        isRegisterError: false,
        registerSuccess: false,
      };
    },
    validations: {
      user: {
        username: {
          required: helpers.withMessage("Username obbligatorio", required),
        },
        firstName: {
          required: helpers.withMessage("Nome obbligatorio", required),
        },
        lastName: {
          required: helpers.withMessage("Cognome obbligatorio", required),
        },
        phoneNumber: {
          required: helpers.withMessage("Numero di telefono obbligatorio", required),
        },
        email: {
          required: helpers.withMessage("Email obbligatoria", required),
          email: helpers.withMessage("Per favore, inserisci una email valida", email),
        },
        password: {
          required: helpers.withMessage("Password obbligatoria", required),
        },
        confirm_password: {
          required: helpers.withMessage("Conferma Password obbligatoria", required),
        },
      },
    },
    computed: {
      notification() {
        return this.$store ? this.$store.state.notification : null;
      },
    },
    mounted(){
      this.getData()
      document.getElementById('auth-particles').style.backgroundImage = this.backgroundImg;
    },
    methods: {
      async register() {
        let tk = this.$route.params.token
        this.submitted = true;
        this.v$.$touch();
        try{
          const result = await axios.post(`${this.UrlServer}auth/register`, {
            username:this.user.username,
            email: this.user.email,
            password: this.user.password,
            confirm_password: this.user.confirm_password,  
            phone: this.user.phoneNumber,
            firstName: this.user.firstName,
            lastName:this.user.lastName,
            token:tk,
          })
          if (result) {
            Swal.fire({
            title:"Registrazione avvenuta!",
            text:"Registrazione andata a buon fine, benvenuti nella nostra applicazione",
            icon:"success",
            showCancelButton:false,
            showConfirmButton:false,
            timer:3000,
            }).then(()=>{
              this.$router.push('/login')
            })
          }
         


        }catch (e) {
         
          let strfyData = JSON.stringify(e)
          console.log( JSON.parse(strfyData).status)
          
            switch(JSON.parse(strfyData).status){
                case 403:
                  Swal.fire({
                    title:"Email già in uso",
                    text:"L'indirizzo email inserito risulta essere già in uso, utilizza un nuovo indirizzo email, per favore",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:3000,
                    })
                break           
              default:
                  Swal.fire({
                    title:"Errore non previsto",
                    text:"Si è verificato un errore, ripeti l'operazione, per favore",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:3000,
                    })
                break;
            }


        }
      },
  
      async getData() {

        this.submitted = true;
        this.v$.$touch();
        try{
          const result = await axios.get(`${this.UrlServer}users/get-email-ftoken/${this.$route.params.token}`)
          console.log(result)
          if(result){
            if (result.data.check==true){
              this.user.email = result.data.email
            }
            
          }
        }catch (e) {
        
                  Swal.fire({
                    title:"Errore non previsto",
                    text:"Si è verificato un errore, ripeti l'operazione, per favore",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:3000,
                    })

        }
      },
      
    },
  }
</script>

<template>
  <div class="auth-page-wrapper pt-5">
    <!-- auth page bg -->
    <div class="auth-one-bg-position auth-one-bg" id="auth-particles">
      <div class="bg-overlay"></div>
    </div>
    <!-- auth page content -->
    <div class="auth-page-content">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center mt-sm-5 mb-4 text-white-50">
              <div>
                <router-link to="/" class="d-inline-block auth-logo">
                  <img :src="config.EP+'/public/assets/img/logo-light.png'" alt="" :height="config.IMG_HEIGHT" />
                </router-link>
              </div>
            
            </div>
          </div>
        </div>
        <!-- end row -->

        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <div class="card mt-4">

              <div class="card-body p-4">
                <div class="text-center mt-2">
                  <h5 class="text-primary">Crea un nuovo account</h5>
                  
                </div>
                <div class="p-2 mt-4">
                  <form class="needs-validation" @submit.prevent="register">
                    <b-alert v-model="registerSuccess" class="mt-3" variant="success" dismissible>Registration
                      successfull.</b-alert>

                    <b-alert v-model="isRegisterError" class="mt-3" variant="danger" dismissible>{{ regError }}
                    </b-alert>

                    <div v-if="notification.message" :class="'alert ' + notification.type">
                      {{ notification.message }}
                    </div>
                    <div class="mb-3">
                      <label for="useremail" class="form-label">Email <span class="text-danger">*</span></label>
                      <input type="email" class="form-control" v-model="user.email" id="useremail" :class="{
                    'is-invalid': submitted && v$.user.email.$error,
                  }" placeholder="Inserisci indirizzo email" readonly="readonly">
                      <div v-for="(item, index) in v$.user.email.$errors" :key="index" class="invalid-feedback">
                        <span v-if="item.$message">{{ item.$message }}</span>
                      </div>
                    </div>
                    <div class="mb-3">
                      <label for="username" class="form-label">Username 
                        <span class="text-danger">*</span></label>
                      <input type="text" class="form-control" v-model="user.username" :class="{
                    'is-invalid': submitted && v$.user.username.$error,
                  }" id="username" placeholder="Inserisci username">
                      <div v-if="submitted && v$.user.username.$error" class="invalid-feedback">
                        <span v-if="v$.user.username.required.$message">{{
                    v$.user.username.required.$message
                  }}</span>
                      </div>
                    </div>
                    <div class="mb-3">
                      <label for="firstName" class="form-label">Nome 
                        <span class="text-danger">*</span></label>
                      <input type="text" class="form-control" v-model="user.firstName" :class="{
                    'is-invalid': submitted && v$.user.firstName.$error,
                  }" id="firstName" placeholder="Inserisci nome utente">
                      <div v-if="submitted && v$.user.firstName.$error" class="invalid-feedback">
                        <span v-if="v$.user.firstName.required.$message">{{
                    v$.user.firstName.required.$message
                  }}</span>
                      </div>
                    </div>
                    <div class="mb-3">
                      <label for="lastName" class="form-label">Cognome 
                        <span class="text-danger">*</span></label>
                      <input type="text" class="form-control" v-model="user.lastName" :class="{
                    'is-invalid': submitted && v$.user.lastName.$error,
                  }" id="lastName" placeholder="Inserisci cognome utente">
                      <div v-if="submitted && v$.user.lastName.$error" class="invalid-feedback">
                        <span v-if="v$.user.lastName.required.$message">{{
                    v$.user.lastName.required.$message
                  }}</span>
                      </div>
                    </div>
                    <div class="mb-3">
                      <label for="phoneNumber" class="form-label">Telefono 
                        <span class="text-danger">*</span></label>
                          <input type="text" class="form-control" v-model="user.phoneNumber" :class="{
                        'is-invalid': submitted && v$.user.phoneNumber.$error,
                      }" id="phoneNumber" placeholder="Inserisci numero di telefono">
                          <div v-if="submitted && v$.user.phoneNumber.$error" class="invalid-feedback">
                            <span v-if="v$.user.phoneNumber.required.$message">{{
                        v$.user.phoneNumber.required.$message
                      }}</span>
                      </div>
                    </div>
                    <div class="mb-3">
                      <label for="userpassword" class="form-label">Password <span class="text-danger">*</span></label>
                      <input type="password" class="form-control" v-model="user.password" :class="{
                    'is-invalid': submitted && v$.user.password.$error,
                  }" id="userpassword" placeholder="Inserisci password">
                      <div v-if="submitted && v$.user.password.$error" class="invalid-feedback">
                        <span v-if="v$.user.password.required.$message">{{
                    v$.user.password.required.$message
                  }}</span>
                      </div>
                    </div>
                    <div class="mb-3">
                      <label for="userpassword" class="form-label">Conferma Password <span
                          class="text-danger">*</span></label>
                      <input type="password" class="form-control" v-model="user.confirm_password" :class="{
                    'is-invalid': submitted && v$.user.password.$error,
                  }" id="userconfirmpassword" placeholder="Conferma password">
                      <div v-if="submitted && v$.user.confirm_password.$error" class="invalid-feedback">
                        <span v-if="v$.user.password.required.$message">{{
                    v$.user.confirm_password.required.$message
                  }}</span>
                      </div>
                    </div>

                    <div class="mb-4">
                     
                    </div>

                    <div class="mt-4">
                      <button class="btn btn-success w-100" type="submit">Registrati</button>
                    </div>


                  </form>

                </div>
              </div>
              <!-- end card body -->
            </div>
            <!-- end card -->

            <div class="mt-4 text-center">
              
            </div>
          </div>
        </div>
        <!-- end row -->
      </div>
      <!-- end container -->
    </div>
    <!-- end auth page content -->

    <!-- footer -->
    <footer class="footer">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center">
              <p class="mb-0 text-muted">
                &copy; {{ new Date().getFullYear() }} 
               <a href="https://www.neuralika.com" target="blank">Neuralika</a> 
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!-- end Footer -->
  </div>
  <!-- end auth-page-wrapper -->
</template>